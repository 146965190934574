<template>
    <LayoutNext>
        <template #page-title>
            Pex Collection Asset
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/pex-collections">Pex Collection</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Pex Collection Asset
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-between mb-4">
                    <template v-if="isLoading">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/cs-loader.png" alt="Loading image">
                        </div>
                    </template>
                    <template v-else>
                        <h5>{{ collectionInfo.title }}</h5>
                        <MDBCardText class="d-md-flex d-block gap-3">
                            <p class="my-auto">
                                <strong>Reference ID:</strong> {{ collectionInfo.referenceId }}
                            </p>
                            <p class="my-auto">
                                <strong>Created At:</strong>
                                {{ parseDateLong(collectionInfo.createdAt) }}
                            </p>
                            <p class="my-auto">
                                <strong>Updated At:</strong>
                                {{ parseDateLong(collectionInfo.updatedAt) }}
                            </p>
                        </MDBCardText>
                    </template>
                </div>
                <div class="d-md-flex d-block justify-content-between">
                    <div class="d-flex justify-content-start gap-2 mb-md-0 mb-3">
                        <MDBBtn class="fw-bold px-3 py-2" color="primary" @click="goToAsset" size="sm">
                            <MDBIcon icon="plus-square" class="me-1" />
                            Add Asset(s)
                        </MDBBtn>
                        <MDBPopover v-model="exportToggle" tag="div">
                            <template #reference>
                                <MDBBtn class="fw-bold text-nowrap" color="primary" tabindex="0"
                                    @click="exportToggle = !exportToggle">
                                    <MDBIcon icon="file-export" class="me-1" />
                                    Export
                                </MDBBtn>
                            </template>
                            <template #body>
                                <MDBSelect v-model:options="exportTypeList" v-model:selected="exportTypeSelected"
                                    class="mb-3" />
                                <MDBAlert color="primary" static class="d-flex align-items-center p-auto">
                                    Please note asset export is limited to 1 million
                                    entries.
                                </MDBAlert>
                                <div class="d-flex justify-content-start">
                                    <MDBBtn class="m-0 fw-bold" size="sm" color="primary" @click.prevent="exportAssets">
                                        Export
                                    </MDBBtn>
                                </div>
                            </template>
                        </MDBPopover>
                    </div>
                    <div class="d-flex justify-content-end gap-2 align-items-center">
                        <MDBInput class="my-auto" placeholder="Search Assets" type="text" v-model="searchInput"
                            inputGroup :formOutline="false">
                        </MDBInput>
                        <MDBDropdown v-model="filterDropdown">
                            <MDBDropdownToggle color="primary" @click="filterDropdown = !filterDropdown"
                                class="rounded-end-0 fw-bold">
                                <MDBIcon icon="filter" class="me-2"></MDBIcon>
                                {{ selectedFilter }}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem tag="div" style="cursor: pointer;"
                                    @click.prevent="selectedFilter = 'All'">
                                    All
                                </MDBDropdownItem>
                                <MDBDropdownItem tag="div" style="cursor: pointer;"
                                    @click.prevent="selectedFilter = 'Tracked Only'">
                                    Tracked Only
                                </MDBDropdownItem>
                                <MDBDropdownItem tag="div" style="cursor: pointer;"
                                    @click.prevent="selectedFilter = 'Untracked Only'">
                                    Untracked Only
                                </MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>

                    </div>
                </div>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <PexCollectionAssetTableNext ref="tableRef" :searchString="searchInput" :isTracked="selectedFilter" />
            </MDBCardBody>
        </MDBCard>
        <MDBToast v-model="exportToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            {{ collectionInfo.title }}'s Asset Exported Successfully
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardHeader, MDBInput, MDBBtn, MDBCardText, MDBPopover, MDBAlert, MDBSelect, MDBIcon, MDBToast, MDBDropdown, MDBDropdownMenu, MDBDropdownItem, MDBDropdownToggle, MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { GetCollection } from "@/services/Collections/GetCollection";
import { parseDateLong } from "@/helpers/parseDate";
import PexCollectionAssetTableNext from "@/views/v3/PexCollection/PexCollectionAssetTableNext.vue";
import { ExportCollectionAssets } from "@/services/Collections/ExportCollectionAssets";
import { useTitle } from "@vueuse/core";

const tableRef = ref(null);
const collectionInfo = ref({});

const searchInput = ref();
watch(searchInput, (newVal) => {
    tableRef.value.setCollections({ searchString: newVal, isTracked: selectedFilter.value });
});

const route = useRoute();
const isLoading = ref(false);
onMounted(async () => {
    isLoading.value = true;
    collectionInfo.value = await GetCollection(route.params.id);
    useTitle(`${collectionInfo.value.title}'s Assets | Creator Shield`);
    isLoading.value = false;
});

const exportTypeList = ref([
    { text: "All Assets", value: null },
    { text: "Tracked Assets Only", value: true },
    { text: "Untracked Assets Only", value: false },
]);

const exportTypeSelected = ref();
const actionToggle = ref(false);
const exportToast = ref(false);

const router = useRouter();
const goToAsset = () => {
    router.push({ name: "AssetsUpload" });
};

const exportToggle = ref(false);
watch(actionToggle, (newVal) => {
    if (newVal && exportToggle.value) {
        exportToggle.value = false;
    }
});

const exportAssets = async () => {
    const isTracked = exportTypeSelected.value;
    const collectionId = route.params.id;
    const response = await ExportCollectionAssets(collectionId, isTracked);

    let fileURL = window.URL.createObjectURL(
        new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
    );

    let fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute(
        "download",
        `CreatorShield-Assets - ${collectionInfo.value.title}.xlsx`
    );

    document.body.appendChild(fileLink);
    fileLink.click();
    exportToggle.value = false;
    exportToast.value = true;
};

const filterDropdown = ref(false);
const selectedFilter = ref("All");

watch(selectedFilter, (newVal) => {
    tableRef.value.setCollections({ isTracked: newVal, searchString: searchInput.value });
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
