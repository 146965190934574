<template>
  <div class="overflow-auto" id="table-container" ref="el">
    <MDBTable hover class="align-middle">
      <thead class="table-light">
        <tr>
          <th class="sticky-top" scope="col" style="z-index: 0;">Asset Name</th>
          <th class="sticky-top" scope="col" style="z-index: 0;">Created</th>
          <th class="sticky-top" scope="col" style="z-index: 0;">Updated</th>
          <th class="sticky-top" scope="col" style="z-index: 1;">Tracking</th>
          <th class="sticky-top" scope="col" style="z-index: 1;">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td colspan="5" class="text-center">
              <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr v-if="isCollectionAssetsEmpty">
            <td colspan="5" class="text-center">No collection asset found.</td>
          </tr>
          <tr class="row-action" v-for="(asset, index) in collectionAssetsList" :key="index"
            @click="goToAssets(asset.assetIdString)">
            <td>{{ asset.title }}</td>
            <td>{{ parseDateLong(asset.createdAt) }}</td>
            <td>{{ parseDateLong(asset.updatedAt) }}</td>
            <td>
              <div class="p-2" style="display: flex;gap: 5px;align-items: center;">
                <MDBBadge :color="asset.isTracked ? 'success' : 'danger'"
                  class="translate-middle p-2 border border-light rounded-circle" dot
                  :title="asset.isTracked ? 'Tracked' : 'Untracked'"
                  style="position: relative;transform: unset !important;">
                </MDBBadge>
                <span>{{ asset.isTracked ? 'Tracked' : 'Untracked' }}</span>
              </div>
            </td>
            <td>
              <PexCollectionAssetActionNext :asset="asset" @toggleStatus="toggleStatus" @deleteAsset="deleteAsset" />
            </td>
          </tr>
        </template>
      </tbody>
    </MDBTable>
    <MDBToast v-model="collectionsAssetsComponentTableToast" :delay="2000" autohide position="top-right" appendToBody
      stacking width="350px" color="success" text="white" icon="fas fa-check fa-lg me-2">
      <template #title> Success </template>
      Asset <strong>{{ assetChangeStatusTitle }}</strong>'s {{ assetChangeStatusDescription }} Successfully
    </MDBToast>
  </div>
</template>

<script setup>
import { MDBTable, MDBBadge, MDBToast } from "mdb-vue-ui-kit";
import { useRoute, useRouter } from "vue-router";
import { useInfiniteScroll, useDebounceFn } from "@vueuse/core";
import { ref, onMounted, defineProps, defineExpose, nextTick } from "vue";
import { GetCollectionAssets } from "@/services/Collections/GetCollectionAssets";
import { parseDateLong } from "@/helpers/parseDate";
import PexCollectionAssetActionNext from "./PexCollectionAssetActionNext.vue";

const props = defineProps({ searchString: { type: String, default: null }, isTracked: { type: String, default: null } });

const router = useRouter();
const route = useRoute();
const collectionAssetsList = ref([]);
const isLoading = ref(false);
const isCollectionAssetsEmpty = ref(false);

const getCollectionAssets = async (lastItem) => {
  isLoading.value = true;
  const response = await GetCollectionAssets({
    collectionId: route.params.id,
    lastItem: lastItem,
    searchString: props.searchString,
    isTracked: setIsTracked(props.isTracked),
  });
  if (response.length === 0 && collectionAssetsList.value.length === 0) {
    isCollectionAssetsEmpty.value = response.length === 0;
  }
  return response;
};

const setIsTracked = (track) => {
  switch (track) {
    case "All":
      return null;
    case 'Tracked Only':
      return true;
    case 'Untracked Only':
      return false;
    default:
      return null;
  }
}

onMounted(async () => {
  collectionAssetsList.value = await getCollectionAssets();
  isLoading.value = false;
});

const setCollections = useDebounceFn(async ({ searchString, isTracked }) => {
  isLoading.value = true;
  collectionAssetsList.value = [];
  isCollectionAssetsEmpty.value = false;
  const response = await GetCollectionAssets({
    collectionId: route.params.id,
    searchString,
    isTracked: setIsTracked(isTracked),
  });
  if (response.length === 0 && collectionAssetsList.value.length === 0) {
    isCollectionAssetsEmpty.value = response.length === 0;
  }
  await nextTick();
  collectionAssetsList.value = response;
  isLoading.value = false;
}, 500);

defineExpose({ setCollections });
const el = ref();
const stopScrollLoad = ref(false);
useInfiniteScroll(
  el,
  async () => {
    if (
      isLoading.value ||
      stopScrollLoad.value ||
      collectionAssetsList.value.length === 0
    ) {
      return;
    }
    const currentArr = [...collectionAssetsList.value];
    const lastItem = currentArr.pop();
    const response = await getCollectionAssets(lastItem.assetIdString);
    if (response.length === 0) {
      stopScrollLoad.value = true;
      isLoading.value = false;
      return;
    }
    collectionAssetsList.value = collectionAssetsList.value.concat(response);
    isLoading.value = false;
  },
  { distance: 10 }
);

const goToAssets = (assetId) => {
  router.push({
    name: "CollectionsAssetsView",
    params: { id: route.params.id, assetId: assetId },
  });
};

const collectionsAssetsComponentTableToast = ref();
const assetChangeStatusTitle = ref("");
const assetChangeStatusDescription = ref("");
const toggleStatus = async (assetTitle) => {
  if (collectionsAssetsComponentTableToast.value) {
    collectionsAssetsComponentTableToast.value = false;
  }
  assetChangeStatusTitle.value = assetTitle;
  assetChangeStatusDescription.value = "Status Changed";
  collectionsAssetsComponentTableToast.value = true;
  collectionAssetsList.value = await getCollectionAssets();
};

const deleteAsset = async (assetTitle) => {
  if (collectionsAssetsComponentTableToast.value) {
    collectionsAssetsComponentTableToast.value = false;
  }
  assetChangeStatusTitle.value = assetTitle;
  assetChangeStatusDescription.value = "Deleted";
  collectionsAssetsComponentTableToast.value = true;
  collectionAssetsList.value = await getCollectionAssets();
};
</script>

<style scoped>
th {
  text-transform: uppercase;
  text-wrap: nowrap
}

tr {
  cursor: default;
}

#table-container {
  flex: 1 1 auto;
  height: 55vh;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgb(153, 153, 153);
  border-radius: 50%;
  animation: BorderSpin 1s linear infinite;
}

.image-container img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@keyframes BorderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>